import { createScopedLocalStorage } from "./local-storage";

const REMOTE_SESSION_ID_KEY = "remote_session_id";

export function saveRemoteSession({ remoteSessionId, apiKey = "" }: { remoteSessionId: string; apiKey?: string }) {
  const beamLocalStorage = createScopedLocalStorage({
    apiKey,
  });
  beamLocalStorage.setItem(REMOTE_SESSION_ID_KEY, remoteSessionId);
}

export function getRemoteSession({ apiKey = "" }: { apiKey?: string }) {
  const beamLocalStorage = createScopedLocalStorage({
    apiKey,
  });
  return beamLocalStorage.getItem(REMOTE_SESSION_ID_KEY);
}
